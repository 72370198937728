import PropTypes from "prop-types";
import React from "react";

const DivListItemCol = (props) => {
    return (
        <div className={"m-2 text-center " + props.className ?? ""}>{props.children}</div>
    )
}

DivListItemCol.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

const DivListItemRow = (props) => {
    return (
        <div className={"flex flex-col " + props.breakPoint + ":flex-row justify-around " + props.className ?? ""} >
            {props.children}
        </div>
    )
}

DivListItemRow.propTypes = {
    children: PropTypes.node.isRequired,
    breakPoint: PropTypes.oneOf(["md", "lg"]).isRequired,
    className: PropTypes.string
}

export {DivListItemCol, DivListItemRow}