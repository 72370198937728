import TextParagraph from "./TextParagraph";
import LinkButton from "./LinkButton";
import React from "react";

const AppointmentDiv = () => {
    return (
        <div className="p-8">
            <div className="m-4">
                <TextParagraph className="">
                    Submit an appointment request today:
                </TextParagraph>
            </div>
            <div className="flex justify-center">
                <LinkButton className="flex-0" to="/appointments">Appointments</LinkButton>
            </div>
        </div>
    )
}

export default AppointmentDiv;