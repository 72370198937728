import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import SEO from "../components/SEO";
import Section, {SectionHeader} from "../components/Section";
import SectionRow from "../components/SectionRow";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import SVGCurve from "../components/SVGCurve";
import LinkButton from "../components/LinkButton";
import AppointmentDiv from "../components/AppointmentDiv";
import InternalLink from "../components/InternalLink";
import {DivListItemCol, DivListItemRow} from "../components/DivListItem";
import DivWithText from "../components/DivWithText";

function ContactPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Contact Information`]}
                title="Contact Information"
                description="Various forms of contacting Agile Psychiatry including the patient portal, phone numbers,
                and address."
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv className="w-full">
                        <div className="m-4 flex flex-col text-xl">
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#phone-numbers">
                                        Phone Numbers
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2" >
                                    <InternalLink to="#appointments">
                                        Appointments
                                    </InternalLink>
                                </DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#patient-questions">
                                        Patient Questions
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#address">
                                        Address
                                    </InternalLink>
                                </DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#medical-records">
                                        Medical Records
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#consulting-speaking">
                                        Consulting / Speaking Requests
                                    </InternalLink>
                                </DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#general-feedback">
                                        General Feedback / Website Problems
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#general-feedback">
                                        Other
                                    </InternalLink>
                                </DivListItemCol>
                            </DivListItemRow>
                        </div>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="phone-numbers">Phone Numbers</SectionHeader>
                        <div className="text-center">
                            <div className="text-2xl mt-2">Office: <a href="tel:5123376684">(512) 337-6684</a></div>
                            <div className="mt-4">Fax: 216-208-1379</div>
                            {/*<div className="mt-4">Fax: (888) 307-6943</div>*/}
                        </div>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="appointments">Appointments</SectionHeader>
                        <DivWithText className="mb-8">
                            If you would like to make an appointment at Agile Psychiatry, please proceed to
                            the <LinkButton to="/appointments" className="inline-block text-base">Appointments</LinkButton> page
                            and submit a request.
                        </DivWithText>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="patient-questions">Patient Questions</SectionHeader>
                        <TextParagraph className="mb-8">
                            In case of emergencies, where you or another person are in danger, <span className="font-bold">please call 9-1-1</span>.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            <span className="font-bold">New Patients:</span> If you are a considering Agile Psychiatry for your care and have a question, we recommend
                            reviewing our <InternalLink to="/services">Services</InternalLink>
                            and <InternalLink to="/frequently-asked-questions">Frequently Asked Questions</InternalLink> pages first.
                            If you were not able to find an answer to your question there, you are welcome to call our
                            clinic at the number below. Note that Dr. Nahas is directly handling all patient inquiries
                            at this time, so we recommend leaving a message and he will return your call.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            <span className="font-bold">Current Patients:</span> If you are a current patient, we recommend contacting your provider via your patient portal. Your question will
                            be directly connected with your medical record, allowing for a quicker response. Someone  from the clinic will follow-up with you as
                            indicated in our practice policies.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="corporate-address">Corporate Address</SectionHeader>
                        <TextParagraph className="mb-8 text-center">
                            2802 Flintrock Trace<br/>
                            Suite 375<br/>
                            Austin, TX 78738<br/>
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="medical-records">Medical Records</SectionHeader>
                        <TextParagraph className="mb-8">
                            If you require medical records, please follow the process on the <InternalLink to="medical-records">Medical Records</InternalLink>
                            page.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="consulting-speaking">Consulting or Speaking Requests</SectionHeader>
                        <TextParagraph className="mb-8">
                            Please submit a request on the <InternalLink to="/consulting">consulting</InternalLink> page.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="general-feedback">General Feedback / Website Problems / Other</SectionHeader>
                        <TextParagraph className="mb-8">
                            If you want to provide some feedback, have encountered a problem with the
                            website that you would like to report, or have some other reason for
                            contacting Agile Psychiatry, please proceed to the
                            <InternalLink to="/feedback">feedback</InternalLink> page.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}

export default ContactPage;
