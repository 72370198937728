import PropTypes from "prop-types";
import React from "react";

function DivWithText({children, className}) {
    let classNameString = "md:text-xl font-normal " + className
    return (
        <div className={classNameString}>
            {children}
        </div>
    )
}

DivWithText.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default DivWithText;