import PropTypes from "prop-types";
import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";

function LayoutGeneral({ children }) {
    return (
        <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-gray-100">
            <Header />

            <Main>
                {children}
            </Main>

            <Footer lastSectionColor="dark"/>
        </div>
    );
}

LayoutGeneral.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutGeneral;
